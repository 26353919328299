import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import Growing from "./growing";
import Harvest from "./harvest";
import MaturationGroup from "./maturationGroup";
import Ownership from "./ownership";

export interface IField extends IModelBase {
  name: string;
  planting_date: string;
  harvest_date: string;
  foreseen_harvest_date?: string;
  foreseen_productivity: number;
  real_productivity?: number;
  area: number;
  feather_efficiency?: number;
  core_efficiency?: number;
  maturation_group_id: number;
  ownership_id: number;
  harvest_id: number;
  growing_id: number;
  is_deleted: boolean;
  deleted_date?: string;

  /**Relationship classes */
  ownership?: Ownership;
  harvest?: Harvest;
  growing?: Growing;
  maturation_group?: MaturationGroup;
}

export class FieldDTO extends ModelBase implements IField {
  name = "";
  planting_date = "";
  harvest_date = "";
  foreseen_harvest_date?: string | undefined = undefined;
  foreseen_productivity = 0;
  real_productivity?: number | undefined = undefined;
  area = 0;
  feather_efficiency?: number | undefined = undefined;
  core_efficiency?: number | undefined = undefined;
  maturation_group_id = 0;
  ownership_id = 0;
  harvest_id = 0;
  growing_id = 0;
  is_deleted = false;
  deleted_date?: string | undefined = undefined;

  ownership = new Ownership();
  harvest = new Harvest();
  growing = new Growing();
  maturation_group?: MaturationGroup | undefined = undefined;

  constructor(dto?: IField) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.planting_date = dto.planting_date;
      this.harvest_date = dto.harvest_date;
      this.foreseen_harvest_date = dto.foreseen_harvest_date;
      this.foreseen_productivity = dto.foreseen_productivity;
      this.real_productivity = dto.real_productivity;
      this.area = stringToNumber(dto.area);
      this.feather_efficiency = stringToNumberOrUndefined(dto.feather_efficiency);
      this.core_efficiency = stringToNumberOrUndefined(dto.core_efficiency);
      this.maturation_group_id = dto.maturation_group_id;
      this.ownership_id = dto.ownership_id;
      this.harvest_id = dto.harvest_id;
      this.growing_id = dto.growing_id;
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto.deleted_date;

      if(dto.ownership)
        this.ownership = new Ownership(dto.ownership);
      if(dto.harvest)
        this.harvest = new Harvest(dto.harvest);
      if(dto.growing)
        this.growing = new Growing(dto.growing);
      if(dto.maturation_group)
        this.maturation_group = new MaturationGroup(dto.maturation_group);
    }
  }
}

export default class Field extends FieldDTO {
  private static resource = "field";

  constructor();
  constructor(field: IField);
  constructor(field?: IField) {
    super(field);
  }

  public static getResource(): string {
    return this.resource;
  }
}
