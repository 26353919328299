
import Vue, { VueConstructor } from "vue";
import FormMixins from "@/mixins/form";
import MaturationGroup from "@/models/maturationGroup";
import Field from "@/models/field";
import Growing from "@/models/growing";

import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  props: {
    ownership_id: Number,
  },
  mixins: [FormMixins],
  data: () => ({
    field: new Field(),
    disabledMaturationGroup: true as boolean,
    maturationGroupSelected: [] as MaturationGroup[],
    sufixies: "sc/ha" as string,
    valid: false as boolean,
  }),
  methods: {
    async save(): Promise<void> {
      if (this.validate()) {
        this.field.ownership_id = this.ownership_id;
        // Mixins
        this.field.planting_date = this.dateISONotNull(
          this.field.planting_date
        );
        if (this.field.foreseen_harvest_date)
          this.field.foreseen_harvest_date = this.dateISONotNull(
            this.field.foreseen_harvest_date
          );
        this.field.harvest_date = this.dateISONotNull(this.field.harvest_date);
        try {
          const field = await crudService.saveClass(
            Field,
            Field.getResource(),
            this.field
          );
          this.$alertSuccess(this.componentsText.mSuccess);
          this.$emit("updateResourceList", field);
          this.close();
        } catch (error) {
          this.$alertError(this.componentsText.mError);
          console.error(error);
        }
      }
    },
    initialize(): void {
      this.field = new Field();
    },
    enableMaturationGroup(): void {
      this.maturationGroupSelected = this.maturationsGroups.filter(
        (mg: MaturationGroup) => mg.growing_id === this.field?.growing_id
      );
      this.maturationGroupSelected?.length > 0
        ? (this.disabledMaturationGroup = false)
        : (this.disabledMaturationGroup = true);
    },
    onChangeGrowing(): void {
      const growing = this.growings.find(
        (growing: Growing) => growing.id === this.field?.growing_id
      );

      if (growing?.symbol === "CT") {
        this.sufixies = "Kg/ha";
      } else {
        this.sufixies = "sc/ha";
      }
    },
    setPDate(): void {
      this.updateHarvestDate();
    },
    updateHarvestDate(): void {
      if (this.field.maturation_group_id) {
        let maturation = this.maturationsGroups.filter(
          (mg: MaturationGroup) => mg.id === this.field?.maturation_group_id
        );
        // Verifica se uma data de plantio for selecionada
        // Senão pega a data de hoje
        let plantDate = null;
        if (this.field.planting_date) {
          plantDate = this.field.planting_date;
        } else {
          this.field.planting_date = new Date().toISOString().substr(0, 10);
          plantDate = this.field.planting_date;
        }
        const dateForenseen = this.$luxon(plantDate);
        this.field.foreseen_harvest_date = dateForenseen
          .plus({ days: maturation[0].days })
          .toString()
          .substr(0, 10);
        this.field.harvest_date = this.field.foreseen_harvest_date;
      } else {
        this.$alertInfo(
          "Dica: Selecione o grupo de maturação antes e veja a data da colheita"
        );
      }
    },
    toUpdate(item: Field): void {
      this.show = true;
      this.$nextTick(() => {
        this.field = new Field(item);
        if (this.field.maturation_group_id) {
          this.enableMaturationGroup();
        }
      });
    },
  },
  computed: {
    componentsText() {
      if (this.field.id) {
        return {
          buttonText: "Atualizar",
          mSuccess: "Talhão atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o talhão",
        };
      } else {
        return {
          buttonText: "Adicionar",
          mSuccess: "Talhão inserido com sucesso!",
          mError: "Houve um problema ao inserir o talhão",
        };
      }
    },
  },
});
