
import Vue, { VueConstructor } from "vue";

import FieldForm from "./forms/FieldForm.vue";
import CrudMixins from "@/mixins/crud";
import Ownership from "@/models/ownership";
import Field from "@/models/field";
import MaturationGroup from "@/models/maturationGroup";

import { mapGetters } from "vuex";
import { IFormFuncs, ItemDataTable } from "@/types/form-types";
import { IParams, ISearchResponse } from "@/types/store-types";
import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  components: {
    FieldForm,
  },
  mixins: [CrudMixins],
  data: () => ({
    localLoading: false as boolean,
    dialog: false as boolean,
    disableAdd: false as boolean,
    ownerships: [] as Ownership[],
    results: {} as ISearchResponse<Field>,

    headers: [
      {
        text: "Nome do talhão",
        tooltip: "Nome registrado do talhão",
        align: "left",
        value: "name",
      },
      {
        text: "Safra",
        tooltip: "Safra atual",
        align: "center",
        value: "harvest.name",
      },
      {
        text: "Cultura",
        tooltip: "Cultura do talhão",
        align: "center",
        value: "growing.name",
      },
      {
        text: "Área do talhão",
        tooltip: "Área total do talhão",
        align: "center",
        value: "area",
      },
      {
        text: "Produtividade",
        tooltip: "Área total do talhão",
        align: "center",
        value: "foreseen_productivity",
      },
      {
        text: "Grupo de maturação",
        tooltip: "Área total do talhão",
        align: "center",
        value: "maturation_group_id",
      },
      {
        text: "Ações",
        align: "center",
        tooltip: "Modificar ou excluir o talhão",
        value: "actions",
        sortable: false,
      },
    ] as ItemDataTable[],
  }),
  async mounted() {
    this.localLoading = true;
    let ownership_id = 0;
    if (this.$route.params.ownership_id) {
      ownership_id = parseInt(this.$route.params.ownership_id);
    }
    try {
      this.ownerships = await this.myOwnerships();
      if (this.ownerships && this.ownerships.length > 0) {
        if (ownership_id) {
          const ownership = this.ownerships.find((o) => o.id === ownership_id);
          if (ownership) this.ownershipSelected = ownership.id;
        } else {
          this.ownershipSelected = this.ownerships[0].id;
        }
        this.searchFields();
      } else {
        this.disableAdd = true;
      }
    } catch (error) {
      console.error(error);
      this.$alertError("Houve um problema ao carregar as propriedades");
    }
  },
  methods: {
    async searchFields(): Promise<void> {
      this.localLoading = true;
      const params: IParams = {
        ownership_id: this.ownershipSelected,
        checkDelete: true,
      };
      try {
        this.results = await crudService.getResourceClass(
          Field,
          Field.getResource(),
          params
        );
      } catch (error) {
        this.$alertError("Erro ao carregar os talhões");
      } finally {
        this.localLoading = false;
      }
    },
    edit(ufield: Field): void {
      const form = this.$refs["form"] as IFormFuncs<Field>;
      if (form && form.toUpdate) form.toUpdate(ufield);
    },
    confirmDelete(dfield: Field): void {
      this.setResource("field");
      dfield.is_deleted = true;
      dfield.deleted_date = new Date().toISOString();
      this.update(dfield)
        .then(() => {
          this.$alertSuccess("Talhão deletedo com sucesso!");
          this.removeFromResourceList(this.results.objects, dfield);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar o talhão!");
        });
    },
    convertMaturationGroup(id: number): string {
      const mg = this.maturationsGroups.find(
        (mg: MaturationGroup) => mg.id === id
      );
      return mg ? mg.name : "Não encontrado";
    },
  },
  computed: {
    ...mapGetters({
      maturationsGroups: "getMaturationGroups",
    }),
    headerFiltered(): ItemDataTable[] {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return [
          this.headers[0],
          this.headers[1],
          this.headers[2],
          this.headers[3],
          this.headers[4],
          this.headers[this.headers.length - 1],
        ];
      }
      return this.headers;
    },
  },
});
